<template>
  <span class="badge" :class="{'badge-default': value === 'AgencyUser', 'badge-primary': value === 'AgencyAdmin' }">{{ formatedValue }}</span>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: ''
      },
      row: {
        type: Object,
      }
    },
    computed: {
      formatedValue() {
        let result = 'User'
        switch(this.value)
        {
          case 'AgencyAdmin':
            result = 'Owner'
            break;
          case 'AgencyUser':
          default:
            result = this.row.user_type_str;
            break;
        }
        return result;
      }
    }
  }
</script>

<style scoped>

</style>
